<template>
	<CHeader fixed with-subheader light>
		<CToggler in-header class="ml-3 d-lg-none" @click="$store.commit('toggleSidebarMobile')" />
		<CToggler
			in-header
			class="ml-3 d-md-down-none"
			@click="$store.commit('toggleSidebarDesktop')"
		/>
		<CHeaderBrand class="mx-auto d-lg-none" to="/">
			<CIcon name="logo" height="48" alt="Logo" />
		</CHeaderBrand>
		<CHeaderNav class="d-md-down-none mr-auto" />
		<!-- <CHeaderNav class="mr-4"> Vi </CHeaderNav> -->
		<CHeaderNav class="mr-4">
			{{ get(currentUser, "fullName") }}
			<TheHeaderDropdownAccnt />
		</CHeaderNav>
		<CSubheader class="px-3">
			<CBreadcrumbRouter class="border-0 mb-0" />
		</CSubheader>
	</CHeader>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const { mapState } = createNamespacedHelpers("authentication")
import { get } from "lodash-es"

export default {
	name: "TheHeader",

	components: {
		TheHeaderDropdownAccnt: () => import("./TheHeaderDropdownAccnt"),
	},

	computed: {
		...mapState({ currentUser: "currentUser" }),
	},

	methods: {
		get,
	},
}
</script>
